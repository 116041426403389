import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useForm } from 'react-hook-form'
import { loginLayoutStyles, LoginIllustration } from '../shared/elements'
import { TextField } from '../shared/fields'
import { login } from '../../services/auth'
import * as API from '../../services/api'

const Container = styled.div`
  ${loginLayoutStyles}
`

export default function NewPasswordForm(props) {
  const { token } = props

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      password: '',
    },
  })

  const onSubmit = async (values) => {
    values.token = token

    setError('')
    setSubmitting(true)

    API.putRequest('/advertiser/new-password', values)
      .then(async (response) => {
        if (response?.data?.success) {
          values.email = response.data.user.email
          await login(values)
          navigate('/')
        } else {
          setSubmitting(false)
          setError(response?.message || 'Something went wrong')
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error)
      })
  }

  return (
    <Container>
      <LoginIllustration />
      <div className="form-container">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SVG src="/images/logo-dark.svg" className="head-logo" />
          <h3>Password reset</h3>

          <TextField
            form={form}
            validation={{ required: true }}
            type="password"
            name="password"
            label="New password"
            autocomplete="new-password"
          />

          {error && <div className="text-danger mb-3">{error}</div>}

          <div className="actions mt-4">
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-primary btn-square"
            >
              Set new password
            </button>
          </div>
        </form>
      </div>
    </Container>
  )
}
